import React, { useState, useEffect } from 'react'
import { InputNumber, Select, DatePicker, TimePicker } from 'antd';
import './CoinCollection.css'
import { LoadingOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/th'

import { openNotification, checkIfIdEqualToZero } from '../../utils/function'
import { apiSGetSite } from '../../services/apis/site'
import { apiSite_middleware, apiSGetMachineBySiteId_middleware } from '../../services/apis/site'
import { apiCGetMachineBySiteId, apiCPushCoin } from '../../services/apis/coin'
import { addTransaction } from "../../resolvers/Mutation";
import { useMutation } from "@apollo/client";
const { Option } = Select;
const { RangePicker } = DatePicker;


const CoinPush = () => {


    const [isLoading, setIsLoading] = useState(false)
    const [sites, setSites] = useState([])
    const [machines, setMachines] = useState([])
    const [coin, setCoin] = useState(undefined)
    const [addcoinTransaction, dataAddTransaction] = useMutation(addTransaction);
    console.log(coin)
    const [isAlertValue, setIsAlertValue] = useState(false)
    const [isAlertSite, setIsAlertSite] = useState(false)
    const [isAlertMachine, setIsAlertMachine] = useState(false)
    const [isAlertPeriod, setIsAlertPeriod] = useState(false)

    useEffect(() => {
        const getStaticDropdown = async () => {
            let site = await apiSite_middleware();

            if (site) {
                console.log(site.message.getSites); // Debugging line to check the sites array
                setSites(site.message.getSites || []);
            }
        };
        getStaticDropdown();
    }, [])

    useEffect(() => {
        //
        let site_code = {
            site_code: coin && coin.site_id
        }
        //console.log(site_code)
        const getMachine = async () => {
            let machine = await apiSGetMachineBySiteId_middleware(site_code)
            console.log(machine.data)
            if (machine.data) setMachines(machine.data || [])
            // setIsAlertMachine(false)
        }
        getMachine()

    }, [coin && coin.site_id])


    const addCoin = async () => {
        // console.log(coin)
        if (!(coin && coin.value) ||
            !(coin && coin.period) ||
            !(coin && coin.value)
        ) {
            if (!(coin && coin.value)) setIsAlertValue(true)
            if (!(coin && coin.site_id)) setIsAlertSite(true)
            if (!(coin && coin.period)) setIsAlertPeriod(true)

        } else {
            setIsLoading(true)
            // let variables = {
            //     "deviceName": coin.deviceName,
            //     "action_by": "coin",
            //     "run_value": (coin.value).toString(),
            //     "run_mode": "0",
            //     "run_time": "0",
            //     "customer_name": "Ac_admin",
            //     "time": moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
            //     "device_startAt": moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
            //     "device_stopAt": moment(coin.period[1]).format("YYYY-MM-DD HH:mm:ss"),
            //     "createAt": moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
            // }
            // // data.admin_id = parseInt(localStorage.getItem('user_id'));
            // // console.log(data);
            // console.log(variables);

            addcoinTransaction({
                variables: {
                    deviceName: coin.deviceName,
                    action_by: "coin",
                    run_value: (coin.value).toString(),
                    run_mode: "0",
                    run_time: "0",
                    customer_name: "Ac_admin",
                    time: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                    device_startAt: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                    device_stopAt: moment(coin.period[1]).format("YYYY-MM-DD HH:mm:ss"),
                    createAt: moment(coin.period[0]).format("YYYY-MM-DD HH:mm:ss"),
                },
            });
            setIsLoading(false)
            openNotification('success', 'Success !', 'Add new coin successful.')

            //let newData = await apiCPushCoin(data)
            // console.log(newData)
            // if (dataAddTransaction) {
            //     setIsLoading(false)
            //     openNotification('success', 'Success !', 'Add new coin successful.')
            //     setCoin(undefined)
            // } else {
            //     setIsLoading(false)
            //     openNotification('error', 'Failed !', 'Failed to add new coin.')
            // }
        }
        //window.location.reload();
    }




    return (
        <div>
            <div className="mg-bt-20 h-d-t-p">Add Coin</div>

            <div className="coin-collect-add-site-grid">

                <div className="f-s-c">Site</div>
                <div className="coin-collect-text-qr">
                    <div className={isAlertSite && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>

                        <Select
                            style={{ width: "100%" }}
                            showSearch
                            placeholder="สถานที่"
                            value={coin && coin.siteCode}
                            onChange={(value) => {
                                setCoin({
                                    ...coin,
                                    site_id: value,
                                    // zone_id: undefined,
                                });
                            }}
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {sites.map((site, idx) => (
                                <Option key={idx} value={site.siteCode} label={`${site.site_name} (${site.siteCode})`}>
                                    {site.site_name} ({site.siteCode})
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                {/* <div className="f-s-c">สถานที่</div>
                <div className={isAlertSite && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
                    <Select
                        style={{ width: "100%" }}
                        showSearch
                        placeholder="สถานที่"
                        value={coin && coin.siteCode}
                        onChange={(value) => {

                            setCoin({
                                ...coin,
                                site_id: value,
                                // zone_id: undefined,
                            });
                        }}
                        optionFilterProp="children"
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    // }
                    >
                       
                        {sites.map((site, idx) => (
                            <Option key={idx} value={site.site_id} siteName={site.site_name} siteCode={site.siteCode}>
                                {site.site_name}
                            </Option>
                        ))}
                    </Select>
                </div> */}
                <div className="f-s-c">Machine</div>
                <div className="coin-collect-text-qr">
                    <div className={isAlertMachine && 'antd-sl-al-bd ovf-hd' || 'ovf-hd'}>
                        <Select style={{ width: '100%' }}
                            placeholder="Machine"
                            disabled={(checkIfIdEqualToZero(coin && coin.site_id))}
                            value={coin && coin.deviceName}
                            onChange={value => {
                                let datamachine = machines.find(({ deviceName }) => deviceName === value)

                                setIsAlertMachine(false)
                                setCoin({ ...coin, deviceName: datamachine.deviceName })
                            }}
                        >
                            {machines.map((machine, idx) =>
                                <Option key={idx} value={machine.deviceName}>{machine.machine_name} {machine.machine_group}</Option>
                            )}
                        </Select>
                    </div>
                </div>

                <div className="f-s-c">จำนวนเงิน</div>
                <div className="coin-collect-text-qr">
                    <div>
                        <InputNumber

                            value={coin && coin.value}
                            placeholder="จำนวนเงิน"
                            style={{ width: '100%', borderColor: isAlertValue ? '#EC1C24' : null }}
                            onChange={value => {
                                setIsAlertValue(false)
                                setCoin({ ...coin, value: value })
                            }}
                        />
                    </div>
                </div>

                <div className="f-s-c">Period</div>
                <div className="coin-collect-text-qr">
                    <RangePicker
                        style={{ width: '100%', borderColor: isAlertPeriod ? '#EC1C24' : null }}
                        showTime
                        placeholder={["Period Start", "Period End"]}
                        allowClear={false}
                        value={coin && coin.period ? coin.period : null}
                        // value={[
                        //     coin && coin.period && coin.period.start_date && moment(coin.period.start_date, 'YYYY-MM-DD HH:mm'),
                        //     coin && coin.period && coin.period.end_date && moment(coin.period.end_date, 'YYYY-MM-DD HH:mm')
                        // ]}
                        onChange={date => {

                            setIsAlertPeriod(false)
                            setCoin({ ...coin, period: date })

                            // let newCoin = { ...coin }
                            // if (!newCoin.period) newCoin.period = {}

                            // newCoin.period.start_date = moment(date[0]).format('YYYY-MM-DD HH:mm')
                            // newCoin.period.end_date = moment(date[1]).format('YYYY-MM-DD HH:mm')
                            // setCoin({ ...coin, period: newCoin })
                        }}
                    />
                </div>


            </div>




            <div className="f-c-c s-e-b">
                <div className="f-c-c d-b-t-n-s d-bg-c-t"
                    style={{ width: '100px', pointerEvents: isLoading ? 'none' : null }}
                    onClick={() => addCoin()}
                >{isLoading ? <LoadingOutlined /> : 'Submit'}</div>
            </div>

        </div>
    )
}

export default CoinPush

