import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Table,
  DatePicker,
  Select,
  Input,
  Pagination,
  Tooltip,
  Modal,
} from "antd";
import "./Transaction.css";
import XLSX from "xlsx";
import { LoadingOutlined } from "@ant-design/icons";

import moment from "moment";
import { useMutation } from "@apollo/client";
import {
  numberWithCommas,
  getSearchParams,
  openNotification,
} from "../../utils/function";
import {
  apiTRSTGetTransaction,
  apiTRSTDel,
  apiSCBTransactionId,
} from "../../services/apis/transaction";
import { getTransationcenter,getTransationrefund, deleteTransationcenter, getTransationcenterSearch } from "../../services/apis/report";
import { DELETE_Transaction } from "../../resolvers/Mutation";

const { RangePicker } = DatePicker;
const { Option } = Select;

const TransactionListRefund = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const p = params.get("p");
  const ttl = params.get("ttl");
  const pmt = params.get("pmt");
  const start = params.get("start");
  const end = params.get("end");
  const sw = params.get("sw");

  const menuList = (localStorage.getItem("list") || "").split(",");

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(Number(p) || 1);
  const [perPage, setPerPage] = useState(Number(ttl) || 20);
  const [startDate, setStartDate] = useState(
    start
      ? moment(start).startOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    end
      ? moment(end).endOf("day").format("YYYY-MM-DD HH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [tableData, setTableData] = useState(undefined);
  const [tableDatalow, setTableDatalow] = useState(undefined);
  const [filterOption, setFilterOption] = useState(pmt || "");
  const [searchWord, setSearchWord] = useState(sw || "");
  const [searchButton, setSearchButton] = useState(false);
  const [waitingForCSV, setWaitingForCSV] = useState(false);
  const [waitingForDel, setWaitingForDel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);

  const [visible, setVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalId, setModalId] = useState(undefined);
  const [visibleTnxId, setVisibleTnxId] = useState(false);
  const [transactionId, setTransactionId] = useState("");

  const [deleteHistory, dataDeleteHistory] = useMutation(DELETE_Transaction);
  useEffect(() => {
    getReport();
    history.push(
      `${location.pathname}${getSearchParams(
        ["p", "ttl", "pmt", "start", "end", "sw"],
        [currentPage, perPage, filterOption, startDate, endDate, searchWord]
      )}`
    );
  }, [currentPage, perPage, searchButton]);
  useEffect(() => {
    if (dataDeleteHistory && isLoadingConfirm) {
      if (dataDeleteHistory?.error) {
        openNotification("error", "Failed !", "Failed to delete this machine.");
        setIsLoadingConfirm(false);
      }
      if (dataDeleteHistory?.data?.deleteHistory) {
        openNotification(
          "success",
          "Success !",
          "Delete this machine successful."
        );
        setVisible(false);
        setIsLoadingConfirm(false);
        setTimeout(() => window.location.reload(false), 500);
      }
    }
  }, [dataDeleteHistory, isLoadingConfirm]);
  useEffect(() => {
    if (dataDeleteHistory && waitingForDel) {
      if (dataDeleteHistory?.error) {
        openNotification("error", "Failed !", "Failed to delete this machine.");
        setWaitingForDel(false);
      }
      if (dataDeleteHistory?.data?.deleteHistory) {
        setWaitingForDel(false);
        setTimeout(() => window.location.reload(false), 500);
      }
    }
  }, [dataDeleteHistory, waitingForDel]);
  const getReport = async () => {
    setIsLoading(true);
    let date = {
      "startDate": startDate,
      "endDate": endDate,
      "currentPage": currentPage,
      "perPage": perPage
    }
    
    let transaction = await getTransationrefund(date);
    if (transaction) {
      console.log(transaction.data)
      setTableDatalow(Number(transaction.data.max_total_rows));
      setIsLoading(false);
      setTableData(transaction.data.data_list);
    }
  };
  const getReportSearch = async () => {
    setIsLoading(true);
    let date = {
      "startDate": startDate,
      "endDate": endDate,
      "currentPage": currentPage,
      "perPage": perPage,
      "keyword": searchWord
    }
    console.log(date)
    let transaction = await getTransationcenterSearch(date);

    if (transaction) {
       console.log(transaction.data)
      setTableDatalow(Number(transaction.data.max_total_rows));
      setIsLoading(false);
      setTableData(transaction.data.data_list);
    }
  };


  const selectDelete = [];
  const handleOnChange = (event) => {
    if (selectDelete.indexOf(event.transaction_id) > -1) {
      selectDelete.splice(selectDelete.indexOf(event.transaction_id), 1);
    } else {
      selectDelete.push(event.transaction_id);
    }
    console.log("Selected : " + selectDelete.length);
  };

  const deleteData = async () => {
    if (!modalId) {
      openNotification("error", "Failed !", "Failed to delete this machine.");
      return;
    }
    console.log(modalId)
    setIsLoadingConfirm(true);
    // deleteHistory({
    //   variables: {
    //     "historyId": modalId,
    //   },
    // });
    let transaction_id = {
      "transaction_id": modalId
    }
    let action = await deleteTransationcenter(transaction_id);
    console.log(action)
    if (action) {
      window.location.reload();
    }
  };


  const columns = [
    {
      title: "",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, index) => (
        <div>
          <input
            type="checkbox"
            id={`select-${data.transaction_id}`}
            name="checkDel"
            onClick={() => handleOnChange(index)}
          />
        </div>
      ),
    },
    {
      title: "#",
      ellipsis: true,
      align: "center",
      width: "5%",
      render: (data, record, index) => (
        <div>{index + 1 + (currentPage - 1) * perPage}</div>
      ),
    },
    {
      title: "วัน-เวลา refund",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {/* {data?.created_at} */}
          {data.created_at ? moment(data.created_at).add(543, "years").format("DD MMM YYYY HH:mm:ss") : "-"}
        </div>
      ),
    },{
      title: "สาขา ที่ใช้งาน",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.site_name_use || "-"}
        </div>
      ),
    },
    {
      title: "วัน-เวลา ที่ใช้งาน",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {/* //{data.device_startAt ? moment(data.device_startAt).add(543, "years").format("HH:mm:ss") : "-"}  */}
          {data.device_start_at ? moment(data.device_start_at).add(543, "years").format("DD MMM YYYY HH:mm:ss") : "-"}
        </div>
      ),
    },
    {
      title: "ประเภท",
      ellipsis: true,
      render: (data) => <div>{data.site_name || "-"}</div>,
    },
    {
      title: "เครื่อง",
      ellipsis: true,
      render: (data) => <div>
        No.{data.machine_name || "-"} {data.machine_type || "-"}
      </div>,
    },
    {
      title: "ลูกค้า",
      ellipsis: true,
      className: "cs-pt",
      render: (data) => (
        <div>
          {data?.customer_name?.length ? data?.customer_name : "-"}
        </div>
      ),
    },
    {
      title: "ช่องทางชำระ",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.action_by == "NOP" ? "Coin" : data.action_by}</div>
      ),
    },
    {
      title: "จำนวนเงิน",
      ellipsis: true,
      align: "center",
      render: (data) => <div>{numberWithCommas(data.run_value || 0, 2)}</div>,
    },{
      title: "ช่องทางคืนเงิน",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.zone}</div>
      ),
    },
    {
      title: "admin",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>{data.run_mode}</div>
      ),
    },
    {
      title: "สถานะ",
      ellipsis: true,
      align: "center",
      render: (data) => (
        <div>
          {data.device_status
            ? data.device_status == 0
              ? "Online"
              : data.device_status == 1
                ? "Completed"
                : data.device_status == -1
                  ? "Offline"
                  : data.device_status
            : "-"}
        </div>
      ),
    },
  
  ];

  const toCSVFile = async () => {
    setWaitingForCSV(true);
    let date = {
      "startDate": startDate,
      "endDate": endDate
    }
    let transaction = await getTransationrefund(date);
    let csvData = [];
    let data = transaction?.data.data_list;
    if (data) {
      (data || []).map((data, idx) => {
        let resultRow = {};
        resultRow["ลำดับ"] = idx + 1;
        resultRow["สาขา ที่ใช้งาน"] = data?.site_name_use|| "";
        resultRow["รหัสสาขา ที่ใช้งาน"] = data?.siteCode_use|| "";
        resultRow["ประเภทสาขา ที่ใช้งาน"] = data?.side_type_use|| "";
        resultRow["เครื่อง"] = `${data.machine_name || ""}`;
        resultRow["รหัสเครื่อง"] = `${data.device_name || ""}`;
        resultRow["ประเภท"] = `${data.machine_type || ""}`;
        resultRow["ลูกค้า"] = `${data.customer_name || ""}`;
        resultRow["ช่องทางชำระ"] = `${data.action_by || ""}`;
        resultRow["จำนวนเงิน"] = `${data.run_value || ""}`;
        resultRow["วัน-เวลา ที่ใช้งาน"] = data.created_at ? moment(data.created_at).add(543, "years").format("DD MMM YYYY HH:mm:ss") : "-";
        // resultRow["ประเภท"] = `${data.site_type ?? ""} ${data.province_id ?? ""
        //   } ${data.zone ?? ""} ${data.site_sub_type ?? ""} ${data.site_code ?? ""
        //   }`;
        // resultRow["site_code"] = data.site_code || "-";
        // resultRow["side_type"] = data.site_type || "-";
        // resultRow["สถานที่"] = data.site_name || "-";
        resultRow["วัน-เวลา refund"] = data?.created_at|| "";
        resultRow["แอดมิน"] = data?.run_mode|| "";
        resultRow["ช่อทงคืนเงิน"] = data?.zone|| "";

        resultRow["สถานะ"] = data.device_status
          ? data.device_status == 0
            ? "Online"
            : data.device_status == 1
              ? "Completed"
              : data.device_status == -1
                ? "Offline"
                : data.device_status
          : "-";
        csvData.push(resultRow);
      });

      const toCSV = XLSX.utils.json_to_sheet(csvData);
      const wb = XLSX.utils.book_new();

      const fileName = "transaction_list";
      XLSX.utils.book_append_sheet(wb, toCSV, fileName);
      XLSX.writeFile(wb, fileName + ".csv");

      setWaitingForCSV(false);
    }
  };

  return (
    <div>
      <div className="mg-bt-20 h-d-t-p">Transaction Center List Refund</div>

      <div className="h-d-t-g-w-d-d-s">
        <div className="h-d-t-g-w-d-d-s-order-2">
          <Select
            value={filterOption}
            style={{ width: "100%" }}
            onChange={(value) => {
              setCurrentPage(1);
              setFilterOption(value);
            }}
          >
            <Option value="">All Payment Type</Option>
            <Option value="coin">Coin</Option>
            <Option value="mobile">Mobile</Option>
            <Option value="tw_pay">TW Pay</Option>
          </Select>
        </div>
        <div className="h-d-t-g-w-d-d-s-order-1">
          <RangePicker
            style={{ width: "100%" }}
            // format={(date) =>  moment(date).add(543, "years").format("D MMM YYYY HH:mm") }
            allowClear={false}
            showTime={{ format: "HH:mm" }}
            value={[
              moment(startDate, "YYYY-MM-DD HH:mm:ss"),
              moment(endDate, "YYYY-MM-DD HH:mm:ss"),
            ]}
            onChange={(date) => {
              setCurrentPage(1);
              setStartDate(
                moment(date[0]).startOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
              setEndDate(
                moment(date[1]).endOf("second").format("YYYY-MM-DD HH:mm:ss")
              );
            }}
            disabledDate={(current) =>
              current && current >= moment().endOf("day")
            }
          />
        </div>
        <div className="h-d-t-g-w-d-d-s-order-3">
          <Input disabled={true}
            placeholder="search .."
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onPressEnter={() => {
              setCurrentPage(1);
              setSearchButton(!searchButton);
              getReportSearch();
            }}
          />
        </div>
        <div 
          className="f-c-c d-b-t-n-s d-bg-c-t h-d-t-g-w-d-d-s-order-4"
          onClick={() => {
            // setCurrentPage(1);
             getReportSearch();
            // setSearchButton(!searchButton);
          }}
        >
          <i className="bi bi-search" style={{ lineHeight: "0" }}></i>
        </div>
        <div
          className="f-c-c d-b-t-n-s d-bg-c-t h-32 h-d-t-g-w-d-d-s-order-5"
          style={{ pointerEvents: waitingForCSV ? "none" : null }}
          onClick={() => toCSVFile()}
        >
          {waitingForCSV ? <LoadingOutlined /> : "Export CSV"}
        </div>
      </div>

      <Table
        bordered
        size="small"
        loading={isLoading}
        rowKey={(record) => record.transaction_id}
        dataSource={(tableData) || []}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
      />

      <div className="pgnt-st">
        <Pagination
          size="small"
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
          total={tableDatalow}
          pageSize={perPage}
          showSizeChanger={false}
        />
      </div>
      <Modal
        title=""
        visible={visible}
        closable={false}
        footer={false}
        centered
      >
        <div className="m-t-i-c" style={{ color: "#FFCB05" }}>
          <i className="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div className="m-t-t-q">Do you want to delete '{modalTitle}' ?</div>
        
        <div className="m-b-y-n-g">
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{ backgroundColor: "#cccccc" }}
            onClick={() => setVisible(false)}
          >
            Cancel
          </div>
          <div
            className="f-c-c d-b-t-n-s b-c-o-i"
            style={{
              backgroundColor: "#3f4d67",
              pointerEvents: isLoadingConfirm ? "none" : null,
            }}
            onClick={() => deleteData()}
          >
            {isLoadingConfirm ? <LoadingOutlined /> : "Confirm"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransactionListRefund;
